/**
 * Timetastic
 * Annual leave tracker page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import SignupButton from "src/components/signupButton"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/landing.scss"
import "src/styles/animations/landing.scss"

// SEO
const title = "Annual leave tracker for hard working teams"
const description =
  "Annual leave tracker for hard working teams. Timetastic is online software that helps you manage and record absence in your business"

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])
  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Why should I consider Timetastic’s annual leave tracker as an alternative to an Excel sheet?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Manual annual leave tracker Excel spreadsheets can be clunky and provide many issues when inputting data that can cause further confusion. Take our example here, where we marked Sophie Jones on holiday from January 1st to January 5th, then marked out as sick from January 8th to January 12th. The issue here is the total number of days the spreadsheet has calculated, and the sick days and annual leave are grouped, which means more headaches for you in fixing the data manually. With Timetastic’s annual leave tracker, you can pop everything from your tracker into one single app. You don’t need to juggle a template, employee emails, and a separate time off calendar. It’s all there in one place, and everything is updated in real time.",
        },
      },
      {
        "@type": "Question",
        name: "Can I get instant annual leave updates?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Annual leave tracker Excel templates require frequent manual checks, which take time. Timetastic’s annual leave tracker is more active than a template — it updates you. With Timetastic, you can get absence summaries sent directly to you. These <a href='https://timetastic.co.uk/features/email-reports/'>absence summaries</a> can be sent to your email, Slack, or MS Teams account. They tell you who has the day off and who has time off coming up in the week. That way you’re always informed. It’s as easy as that!",
        },
      },
      {
        "@type": "Question",
        name: "Can I use Timetastic to integrate with other applications?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "You and your team can sync your leave calendar to your everyday work calendar (<a href='https://help.timetastic.co.uk/hc/en-us/articles/360020971894-Calendar-integration-Google-Calendar'>Google Calendar</a>, <a href='https://help.timetastic.co.uk/hc/en-us/articles/202170742-Calendar-feeds-ical-webcal'>iCal</a>, <a href='https://help.timetastic.co.uk/hc/en-us/articles/360020969554-Calendar-Integration-Outlook-Office-365'>Outlook</a>, etc.). It’s as easy as adding a link to your <a href='https://timetastic.co.uk/blog/google-calendar-leave-management/'>Google leave calendar</a>. From there, you can easily see who’s off in your company or department by simply checking that. See our <a href='https://help.timetastic.co.uk/hc/en-us/categories/201656645-Integrations'>integration set-up guides</a> for step-by-step instructions on integrating with other apps and services.",
        },
      },
      {
        "@type": "Question",
        name: "Is it easier to add and remove team members using Timetastic as my business grows?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<a href='https://timetastic.co.uk/blog/managing-annual-leave/'>Managing annual leave</a> sufficiently requires a platform you can rely on to get things done promptly. An annual leave Excel tracker requires a painstaking approach, deleting rows, adding new filters and tricky conditional formatting rules. Eradicate any fuss with Timetastic and create an individual profile for each employee, so you can drill down and look at their personal leave history — something you can’t easily do with an Excel template.",
        },
      },
      {
        "@type": "Question",
        name: "What type of businesses can Timetastic help?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Regardless of your industry, Timetastic is here to help you. We’ve partnered with every type of business you can think of such as: Legal firms, IT consultants, Retail businesses, Charitable organisations, Marketing agencies, Logistics companies.",
        },
      },
      {
        "@type": "Question",
        name: "More questions?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Take a look at our <a href='https://help.timetastic.co.uk/hc/en-us'>Help Centre</a> for detailed articles on getting the most for Timetastic or contact our friendly <a href='https://timetastic.co.uk/support/'>Support Team</a>.",
        },
      },
    ],
  }

  return (
    <>
      <SEO title={title} description={description} schemaMarkup={schema} />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <section
            className="c-section c-landing-screenshots is-unanimated c-landing-content c-landing-bullet"
            id="screenshots"
          >
            <div className="u-inner u-inner--l c-landing-screenshots__inner">
              <div>
                <h1 className="h h2 c-landing-screenshots__title">
                  Trade your Excel spreadsheet for a modern{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    annual leave tracker
                  </span>
                </h1>
                <p>Track annual leave the smart way with Timetastic.</p>
                <div className="c-landing-cta">
                  <SignupButton
                    buttonCopy="Start a free trial today"
                    trackCtaLabel="AnnualLeaveTrackerHeroSignUp"
                  />
                  <span className="c-free-trial__no-link">
                    Free for one month — no card required
                  </span>
                </div>

                <p>
                  Streamline your business operations with a hassle-free
                  approach to tracking annual leave. Timetastic’s annual leave
                  tracker is an upgrade from your standard Excel spreadsheet.
                  Sign up today and you’ll be able to:{" "}
                </p>
                <ul>
                  <li>
                    Automatically track annual leave and always keep people’s
                    leave balances up-to-date.{" "}
                  </li>
                  <li>
                    Receive and respond to new time off requests from your team.
                  </li>
                  <li>
                    Give your team access to a time off calendar that’s updated
                    automatically.
                  </li>
                </ul>
                <p>
                  Say goodbye to your manual spreadsheets and start{" "}
                  <a
                    aria-label="Comparing 3 popular ways to keep an annual leave record"
                    href="https://timetastic.co.uk/blog/annual-leave-record/"
                    onClick={() => {
                      trackCta("AnnualLeaveTrackerIntro")
                    }}
                  >
                    recording annual leave
                  </a>{" "}
                  efficiently.
                </p>
              </div>

              <div className="c-landing-screenshots__images is-unanimated">
                <BackgroundBlobs blobType="howHeroBlobs">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-landing-screenshots__tablet"
                  />
                  <StaticImage
                    src="../images/hero-mobile-booking.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-landing-screenshots__mobile"
                  />
                </BackgroundBlobs>
              </div>
              <div className="c-landing-screenshots__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                  loading="eager"
                />
              </div>
            </div>
          </section>
          <section className="c-section c-landing-bullet">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated">
                <h2 className="h h2 u-text-centre-desktop">
                  Annual leave{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    Excel tracker drawbacks
                  </span>
                </h2>
              </div>

              <div className="c-landing">
                <div className="c-feature c-feature--calendars">
                  <div className="is-unanimated">
                    <div className="c-feature--calendars__blob-01">
                      <BackgroundBlobs blobType="howCalendarBlobs">
                        <div className="c-feature-calendars-grid__item">
                          <StaticImage
                            src="../images/calendars-months.jpg"
                            width={619}
                            alt="Calendar months"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-months c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-months-2.jpg"
                            width={627}
                            alt="Calendar months"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-months-2 c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-summary.jpg"
                            width={306}
                            alt="Leave summary"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-summary c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-breakdown.jpg"
                            width={306}
                            alt="Your time off"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-feature-calendars-grid__image c-feature-calendars-grid__image-breakdown"
                          />
                          <StaticImage
                            src="../images/calendars-trends.jpg"
                            width={306}
                            alt="Annual trends"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-feature-calendars-grid__image c-feature-calendars-grid__image-trends"
                          />
                        </div>
                      </BackgroundBlobs>
                    </div>
                  </div>
                  <div className="is-unanimated c-landing-bullet-cross">
                    <p>
                      It’s easy to find a free Excel template to download to
                      track annual leave for your staff. You’ll find plenty
                      online, but do they really offer efficiency?
                    </p>
                    <h3 className="h h3">
                      An annual leave Excel template means:
                    </h3>
                    <ul>
                      <li>
                        Manually updating your sheet increases the chance of
                        errors and incorrect info.
                      </li>
                      <li>
                        An inability to manage leave requests synchronously
                        which means your team will likely reach out to you via
                        Slack, text, email, or in-person to{" "}
                        <a
                          aria-label="Leave request software: how to manage time off requests"
                          href="https://timetastic.co.uk/blog/leave-request-software/"
                          onClick={() => {
                            trackCta("AnnualLeaveTrackerRequest")
                          }}
                        >
                          request time off
                        </a>
                        .
                      </li>
                      <li>
                        Constant handling of leave requests through messages and
                        emails.
                      </li>
                      <li>
                        Frequent spreadsheet maintenance as the calendar year
                        progresses or as your business grows. More team members
                        require more changes to your template.
                      </li>
                    </ul>
                    <p>
                      Access to Timetastic makes managing your staff a whole lot
                      easier.
                    </p>
                  </div>
                </div>
                <div className="c-feature c-feature__notifications">
                  <div className="is-unanimated">
                    <h3 className="h h3">
                      An annual leave tracker alternative that ticks all the
                      boxes
                    </h3>
                    <p>
                      Every business owner, manager and team leader loves a tool
                      that can make management simpler. Timetastic’s annual
                      leave tracker does just that — and then some! Sign up
                      today and you’ll get access to these handy features
                      instantly:
                    </p>
                    <p>
                      <ul>
                        <li>
                          Visibility across your whole business for total
                          transparency on annual leave.
                        </li>
                        <li>
                          See{" "}
                          <a
                            aria-label="Public holidays"
                            href="https://help.timetastic.co.uk/hc/en-us/articles/360000329213-Public-holidays"
                            onClick={() => {
                              trackCta("AnnualLeaveTrackerPublicHolidays")
                            }}
                          >
                            public holidays
                          </a>{" "}
                          from countries worldwide and assign accordingly to
                          staff in the UK and overseas.
                        </li>
                        <li>
                          Monitor individual annual leave allowances easily.
                        </li>
                        <li>
                          Generate analytical reports with insights on usage and
                          compliance requirements.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="c-feature-notifications-grid__item">
                    <StaticImage
                      src="../images/notifications-request.jpg"
                      width={249}
                      alt="Request notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request"
                    />
                    <StaticImage
                      src="../images/notifications-approval.jpg"
                      width={249}
                      alt="Approval notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval"
                    />
                    <StaticImage
                      src="../images/notifications-request.jpg"
                      width={464}
                      alt="Request notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request-s"
                    />
                    <StaticImage
                      src="../images/notifications-approval.jpg"
                      width={464}
                      alt="Approval notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval-s"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="c-section c-landing-gradient__grey">
            <div className="u-inner u-inner--l is-unanimated">
              <h2 className="h h1 u-text-center">
                Get on top of annual leave with Timetastic
              </h2>
              <div className="c-home-grid__annotation">
                <span className="c-home-grid__annotation--text c-home-grid__annotation--top-left c-home-grid__annotation--hide">
                  Your team
                </span>
                <span className="c-home-grid__annotation--text c-home-grid__annotation--top-right c-home-grid__annotation--hide">
                  Book time off
                </span>
                <StaticImage
                  src="../images/live-wallchart.jpg"
                  width={1200}
                  alt="Wallchart"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart"
                />
                <StaticImage
                  src="../images/live-wallchart-mobile.jpg"
                  width={440}
                  alt="Wallchart"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart-s"
                />
                <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom-mid-right c-home-grid__annotation--hide">
                  Pending request
                </span>
              </div>
            </div>
          </section>
          <section className="c-section c-landing-gradient__green c-landing-content">
            <div className="u-inner u-inner--l is-unanimated ">
              <h2 className="h h2">Flexible annual leave tracking</h2>
              <div className="c-landing-seo">
                <div>
                  <h3 className="h h3">Personalised calendars</h3>
                  <p>
                    Individual leave calendars for each employee — transparency
                    with a personal touch.
                  </p>
                  <h3 className="h h3">Locked dates</h3>
                  <p>
                    Use our handy lock feature to block out leave for staff
                    training or corporate away days.
                  </p>
                  <h3 className="h h3">TOIL tracking</h3>
                  <p>
                    Employees can utilise the Timetastic app to submit any{" "}
                    <a
                      aria-label="What is TOIL? (with good examples)"
                      href="https://timetastic.co.uk/blog/what-is-toil/"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerToil")
                      }}
                    >
                      time off in lieu
                    </a>{" "}
                    of hours.
                  </p>
                  <h3 className="h h3">Customised leave types</h3>
                  <p>
                    Create your own custom{" "}
                    <a
                      aria-label="Setting different types of leave"
                      href="https://help.timetastic.co.uk/hc/en-us/articles/202277731-Setting-different-types-of-leave"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerLeaveTypes")
                      }}
                    >
                      leave types
                    </a>{" "}
                    such as{" "}
                    <a
                      aria-label="Maternity leave — a short guide to what you’re entitled to"
                      href="https://timetastic.co.uk/blog/maternity-leave-what-are-you-entitled-to"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerMaternity")
                      }}
                    >
                      maternity
                    </a>
                    ,{" "}
                    <a
                      aria-label="A Guide to Paternity Leave in the UK"
                      href="https://timetastic.co.uk/blog/a-guide-to-paternity-leave-in-the-uk"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerPaternity")
                      }}
                    >
                      paternity
                    </a>
                    ,{" "}
                    <a
                      aria-label="How do compassionate leave and bereavement leave work?"
                      href="https://timetastic.co.uk/blog/compassionate-leave-bereavement-leave"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerCompassionate")
                      }}
                    >
                      compassionate and bereavement leave
                    </a>
                    .
                  </p>
                  <h3 className="h h3">Set up department groups</h3>
                  <p>
                    Group employees by department, set minimum staffing levels
                    and highlight any clashes before approving any leave
                    requests.
                  </p>
                  <h3 className="h h3">Monitor stress and burnout levels</h3>
                  <p>
                    Take care of your staff with our{" "}
                    <a
                      aria-label="What burnout is, and how to avoid it"
                      href="https://timetastic.co.uk/blog/what-burnout-is-and-how-to-avoid-it"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerBurnoutBoard")
                      }}
                    >
                      Burnout Board
                    </a>{" "}
                    and encourage staff to take leave.
                  </p>
                </div>
                <div className="c-landing-seo__media">
                  <a
                    href="https://www.capterra.com/reviews/146655/Timetastic?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="capterra"
                  >
                    {" "}
                    <img
                      loading="lazy"
                      border="0"
                      src="https://assets.capterra.com/badge/3fb93b4fa79a460bf121a4febd992c0c.png?v=2103571&p=146655"
                    />
                  </a>
                  <a
                    href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="https://media.reviews.co.uk/badge/timetastic.png"
                    />
                  </a>
                  <a
                    href="https://uk.trustpilot.com/review/timetastic.co.uk"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="/images/features/social-proof/trustpilot-badge.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section c-landing-faq c-landing-content">
            <div className="u-inner u-inner--l is-unanimated">
              <h2 className="h h2">Annual leave tracker FAQs</h2>
              <ul>
                <li>
                  <div className="c-features-grid__item">
                    <div className="c-features-grid__description">
                      <h3 className="h h4">
                        Why should I consider Timetastic’s annual leave tracker
                        as an alternative to an Excel sheet?
                      </h3>
                      <p>
                        Manual annual leave tracker Excel spreadsheets can be
                        clunky and provide many issues when inputting data that
                        can cause further confusion. Take our example here,
                        where we marked Sophie Jones on holiday from January 1st
                        to January 5th, then marked out as sick from January 8th
                        to January 12th.
                      </p>
                      <p>
                        The issue here is the total number of days the
                        spreadsheet has calculated, and the sick days and annual
                        leave are grouped, which means more headaches for you in
                        fixing the data manually. With Timetastic’s annual leave
                        tracker, you can pop everything from your tracker into
                        one single app. You don’t need to juggle a template,
                        employee emails, and a separate time off calendar. It’s
                        all there in one place, and everything is updated in
                        real time.
                      </p>
                    </div>
                    <div className="c-features-grid__media">
                      <img
                        loading="eager"
                        alt="Week ahead email report"
                        className="c-features-grid__image"
                        src="/images/landing/spreadsheet-example.png"
                        width={539}
                        height={288}
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c-features-grid__item">
                    <div className="c-features-grid__description">
                      <h3 className="h h4">
                        Can I get instant annual leave updates?
                      </h3>
                      <p>
                        Annual leave tracker Excel templates require frequent
                        manual checks, which take time. Timetastic’s annual
                        leave tracker is more active than a template — it
                        updates you. With Timetastic, you can get absence
                        summaries sent directly to you.
                      </p>

                      <p>
                        These{" "}
                        <a
                          aria-label="Absence summaries"
                          href="https://timetastic.co.uk/features/email-reports/"
                          onClick={() => {
                            trackCta("AnnualLeaveTrackerAbsenceSummaries")
                          }}
                        >
                          absence summaries
                        </a>{" "}
                        can be sent to your email, Slack, or MS Teams account.
                        They tell you who has the day off and who has time off
                        coming up in the week. That way you’re always informed.
                        It’s as easy as that!
                      </p>
                    </div>
                    <div className="c-features-grid__media">
                      <img
                        loading="eager"
                        alt="Week ahead email report"
                        className="c-features-grid__image"
                        src="/images/features/email-reports/your-week-ahead.png"
                        width={539}
                        height={288}
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <h3 className="h h4">
                    Can I use Timetastic to integrate with other applications?
                  </h3>
                  <p>
                    You and your team can sync your leave calendar to your
                    everyday work calendar (
                    <a
                      aria-label="Calendar integration: Google Calendar"
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360020971894-Calendar-integration-Google-Calendar"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerIntegrationGoogle")
                      }}
                    >
                      Google Calendar
                    </a>
                    ,{" "}
                    <a
                      aria-label="Calendar feeds: ical / webcal"
                      href="https://help.timetastic.co.uk/hc/en-us/articles/202170742-Calendar-feeds-ical-webcal"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerIntegrationIcal")
                      }}
                    >
                      iCal
                    </a>
                    ,{" "}
                    <a
                      aria-label="Calendar Integration: Outlook & Office 365"
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360020969554-Calendar-Integration-Outlook-Office-365"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerIntegrationOutlook")
                      }}
                    >
                      Outlook
                    </a>
                    , etc.). It’s as easy as adding a link to your{" "}
                    <a
                      aria-label="The best Google Calendar leave management system"
                      href="https://timetastic.co.uk/blog/google-calendar-leave-management/"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerGoogleCalendar")
                      }}
                    >
                      Google leave calendar
                    </a>
                    . From there, you can easily see who’s off in your company
                    or department by simply checking that. See our{" "}
                    <a
                      aria-label="Integrations — Linking and running Timetastic with other apps and services"
                      href="https://help.timetastic.co.uk/hc/en-us/categories/201656645-Integrations"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerIntegrationGuides")
                      }}
                    >
                      integration set-up guides
                    </a>{" "}
                    for step-by-step instructions on integrating with other apps
                    and services.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    Is it easier to add and remove team members using Timetastic
                    as my business grows?
                  </h3>
                  <p>
                    <a
                      aria-label="Managing annual leave: 5 things you need to get right"
                      href="https://timetastic.co.uk/blog/managing-annual-leave/"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerGoogleCalendar")
                      }}
                    >
                      Managing annual leave
                    </a>{" "}
                    sufficiently requires a platform you can rely on to get
                    things done promptly. An annual leave Excel tracker requires
                    a painstaking approach, deleting rows, adding new filters
                    and tricky conditional formatting rules. Eradicate any fuss
                    with Timetastic and create an individual profile for each
                    employee, so you can drill down and look at their personal
                    leave history — something you can’t easily do with an Excel
                    template.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    What type of businesses can Timetastic help?
                  </h3>
                  <p>
                    Regardless of your industry, Timetastic is here to help you.
                    We’ve partnered with every type of business you can think of
                    such as:
                  </p>
                  <div className="c-landing-bullet">
                    <ul>
                      <li>Legal firms</li>
                      <li>IT consultants</li>
                      <li>Retail businesses</li>
                      <li>Charitable organisations</li>
                      <li>Marketing agencies</li>
                      <li>Logistics companies</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h3 className="h h4">More questions?</h3>
                  <p>
                    Take a look at our{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us"
                      rel="noopener"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerFaqHelp")
                      }}
                    >
                      Help Centre
                    </a>{" "}
                    for detailed articles on getting the most for Timetastic or
                    contact our friendly{" "}
                    <Link
                      to="/support/"
                      onClick={() => {
                        trackCta("AnnualLeaveTrackerFaqSupport")
                      }}
                    >
                      <span>Support Team</span>
                    </Link>
                    .
                  </p>
                </li>
              </ul>
            </div>
          </section>

          <section className="is-unanimated c-section u-text-center c-free-trial c-free-trial--gradient-bottom">
            <div className="u-inner">
              <h2 className="h h2 c-free-trial__title u-text-left-mobile">
                Happier with Timetastic
              </h2>
              <div className="u-inner c-free-trial__copy u-text-left-mobile">
                <Link
                  className="u-em-link"
                  to="/customers/"
                  onClick={() => {
                    trackCta("AnnualLeaveTrackerCustomers")
                  }}
                >
                  <span>Read how people like you use Timetastic</span>
                </Link>
                <p>
                  Their stories tell us how they took control of absence
                  management and became healthier, happier places to work
                </p>
                <h2 className="h h4">Want to see how it works?</h2>
                <a
                  className="u-em-link u-text-left-mobile"
                  href="https://timetastic.ewebinar.com/webinar/timetastic-product-demo-6394"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("eWebinar")
                  }}
                >
                  <span>Watch our 10 minute webinar</span>
                </a>
                {/* <span className="u-text-left-mobile"> or </span>
                <a
                  className="u-em-link u-text-left-mobile"
                  href="https://calendly.com/sarah-timetastic/timetastic-demo"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("BookDemo")
                  }}
                >
                  <span>book a live demo</span>
                </a> */}
              </div>
            </div>
          </section>

          <section className="c-section u-text-centre u-text-left-mobile c-landing-apps">
            <div className="u-inner u-inner--ms is-unanimated">
              <h2 className="h h2">
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  Mobile apps
                </span>{" "}
                too
              </h2>
              <p>
                Your team can make use of the leave management software apps for
                Android and Apple to book an absence. No need to be in the
                office, everything is instantly tracked!
              </p>
            </div>
            <div className="u-inner u-inner--ms">
              <ul className="c-badge-list is-unanimated">
                <li className="c-badge-list__item">
                  <a
                    className="c-badge-list__link"
                    href="https://itunes.apple.com/gb/app/timetastic-nicer-way-to-book/id633985417?mt=8"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("iOSappclick")
                    }}
                  >
                    <img
                      loading="lazy"
                      alt="Download on App Store"
                      className="c-badge-list__image"
                      height="84"
                      src="/images/badges/app-store.png"
                      width="252"
                    />
                  </a>
                </li>
                <li className="c-badge-list__item">
                  <a
                    className="c-badge-list__link"
                    href="https://play.google.com/store/apps/details?id=com.mediaburst.timetastic&hl=en"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("Androidappclick")
                    }}
                  >
                    <img
                      loading="lazy"
                      alt="Get it on Google Play"
                      className="c-badge-list__image"
                      height="84"
                      src="/images/badges/google-play.png"
                      width="282"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <FreeTrial
            body="Timetastic has been helping people book their holidays since 2012. Doctors, web design agencies, car dealers, online retailers, charities — over 8,000 teams and small businesses just like yours."
            buttonCopy="Start booking holidays now"
            title="200,000+ people use Timetastic"
            trackCtaLabel="AnnualLeaveTrackerSignUpFooter"
            imgClass="c-free-trial__user-graph"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
  }
`
